<template>
  <div class="flex flex-col h-75vh items-center justify-center">
    <h5 class="text-lg font-semibold text-primary">
      Tell us a bit more about yourself​
    </h5>
    <div class="mt-4 flex">
      <router-link
        class="w-max px-4 py-1 mr-1 bg-softBlue text-pulpBlue hover:bg-secondary hover:text-white transition-all underline cursor-pointer"
        to="/schooluser"
      >
        School or University User
      </router-link>
      <router-link
        class="w-max px-4 py-1 ml-1 bg-softBlue text-pulpBlue hover:bg-secondary hover:text-white transition-all underline cursor-pointer"
        to="/businessuser"
      >
        Business User​
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>